import Link from 'next/link'
import { Button } from '@thepokencompany/ui'

import AppLayout from './AppLayout'

export default function NotFound() {
  return (
    <AppLayout>
      <div className='flex flex-col items-center w-full'>
        <div className='max-w-screen-md flex flex-row w-full mb-12 px-4 justify-center items-center text-center'>
          <div className='py-24 flex flex-col justify-center items-center'>
            <img className='my-2' src='/images/question.png' />
            <p className='my-2 text-gtxt2 text-3xl'>404 Error</p>
            <p className='my-2 text-5xl'>Sorry, the page you are looking doesnt seem to exist.</p>
            <Link href={'/'}>
              <Button className='my-2'>Home</Button>
            </Link>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
